import { ArrowRight } from "@mui/icons-material";
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

interface Props {
  features: string[];
  renew: boolean;
  onClick: () => void;
}

const FeatureListAndPurchaseButton: React.FC<Props> = (props) => {
  return (
    <Paper variant="outlined" style={{ padding: "2em" }}>
      <Container>
          {props.renew ?
            <> 
              <Typography variant="h5" align="center" gutterBottom>
                ¡Renueva tu suscripción Premium!
              </Typography>
              <Typography>
                Tu suscripción Premium ha vencido. Para poder continuar usando los servicios, 
                es necesario renovar la suscripción con un único pago de AR$ 5000 a través de 
                MercadoPago. Podrás acceder a los siguientes algoritmos de forma inmediata.
              </Typography>
            </>
          :
            <>
              <Typography variant="h5" align="center" gutterBottom>
                ¡Obtén tu suscripción Premium!
              </Typography>
              <Typography>
                Para poder usar los servicios, es necesario comprar el servicio
                Premium con un único pago de ARS$ 5000 a través de MercadoPago. Una vez
                realizado el pago, podrás acceder a los siguientes algoritmos de
                manera inmediata. 
              </Typography>
            </>
          }

        <List>
          {props.features.map((value) => (
            <ListItem key={value}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText primary={value} />
            </ListItem>
          ))}
        </List>

        <Button fullWidth variant="contained" onClick={props.onClick}>
          Comprar servicio premium
        </Button>
      </Container>
    </Paper>
  );
};

export default FeatureListAndPurchaseButton;
